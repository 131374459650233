<template>
  <div class="slidebar text-left">
    <div v-for="item in slibar" :key="item.title" v-show="item.show">
      <div
        class="title textperson-t1 text-16 flex js-st text-bold"
        :class="{
          'title-active': titleActive(item),
        }"
      >
        <div class="round mr-8">
          <img :src="item.img" class="round" />
        </div>
        {{ item.title }}
      </div>
      <ul class="namebox">
        <div
          v-for="item1 in item.children"
          :key="item1.name"
          @click="goUrl(item1.path)"
          replace
          :class="{ 'activetab text-bold': nameActive(item1.path) }"
          class="router-link name textperson-t2 text-14 point"
          v-show="item1.show"
        >
          {{ item1.name }}
        </div>
      </ul>
    </div>
    <Messagebox
      :text="$t('formTip.cashTip')"
      :dialog="state.dialogSafetyTip"
      @viewSafeStatus="viewSafeStatus"
      :type="state.safetyType"
      @onconfirm="confirm"
      @oncancer="onCancel"
    />
    <VerifiedBox
      :text="$t('formTip.certificationTip')"
      :dialog="state.dialogVerfiedTip"
      :type="state.type"
      @onconfirm="onVerfiedConfirm"
      @viewSafeStatus="onVerfiedConfirm"
      @oncancer="onVerfiedCancel"
    />
  </div>
</template>

<script>
import { reactive, computed, onMounted, watch, nextTick, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Messagebox from "@/components/messageBox";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import VerifiedBox from "@/components/verifiedBox";
import UseSystemSetting from "@/commonapi/useSystemSetting";
export default {
  name: "Slidebar",
  components: { Messagebox, VerifiedBox },
  setup() {
    const { t } = useI18n();
    const $store = useStore();
    const $route = useRoute();
    const $router = useRouter();
    // 获取开关状态
    const {
      platform_content_management,
      virtual_wallet,
      bank_withdraw,
      c2c_withdraw,
    } = UseSystemSetting();
    const state = reactive({
      safetyType: 0,
      type: 0,
      dialogSafetyTip: false,
      dialogVerfiedTip: false,
    });
    const slibar = ref([
      {
        title: t("user.person"),
        img: require("@/assets/images/person.png"),
        show: true,
        children: [
          {
            name: t("user.accountSettings"),
            path: "/person/accountSet",
            show: true,
          },
          { name: t("user.invite"), path: "/person/myinvita", show: true },
        ],
      },
      {
        title: t("NFT.manage"),
        img: require("@/assets/images/NFT.png"),
        show: !virtual_wallet.value,
        children: [
          {
            name: t("NFT.MyCollection"),
            path: "/person/NFTManage?active=1",
            show: true,
          },
          {
            name: t("NFT.transRecord"),
            path: "/person/NFTManage?active=2",
            show: true,
          },
          {
            name: t("NFT.TransferRecord"),
            path: "/person/NFTManage?active=3",
            show: true,
          },
        ],
      },
      {
        title: t("user.assets"),
        img: require("@/assets/images/asset.png"),
        show: true,
        children: [
          {
            name: t("assets.myAssets"),
            path: "/person/myassets",
            show: true,
          },
          {
            name: t("assets.recharge", { space: "　　" }),
            path: "/person/recharge",
            show: !virtual_wallet.value,
          },
          {
            name: t("assets.withdrawMoney", { space: "　　" }),
            path: "/person/cash",
            show: !virtual_wallet.value,
          },
          {
            name: t("assets.assetRecord"),
            path: "/person/AssetsRecord",
            show: !virtual_wallet.value,
          },
        ],
      },
      {
        title: t("global.entrustedManagement"),
        img: require("@/assets/images/commit.png"),
        show: true,
        children: [
          {
            name: t("global.currentCommission"),
            path: "/person/ComminManage?active=1",
            show: true,
          },
          {
            name: t("global.historyCommission"),
            path: "/person/ComminManage?active=2",
            show: true,
          },
        ],
      },
      {
        title: t("global.contractManagement"),
        img: require("@/assets/images/contract.png"),
        show: true,
        children: [
          {
            name: t("global.currentPosition"),
            path: "/person/contract?active=1",
            show: true,
          },
          {
            name: t("global.currentCommission"),
            path: "/person/contract?active=2",
            show: true,
          },
          {
            name: t("global.historyRecord"),
            path: "/person/contract?active=3",
            show: true,
          },
        ],
      },
      {
        title: t("stock.stockInvestment"),
        img: require("@/assets/images/stock.png"),
        show: true,
        children: [
          {
            name: t("global.currentPosition"),
            path: "/person/stockTrad?active=1",
            show: true,
          },
          {
            name: t("global.currentCommission"),
            path: "/person/stockTrad?active=2",
            show: true,
          },
          {
            name: t("global.historyRecord"),
            path: "/person/stockTrad?active=3",
            show: true,
          },
        ],
      },
      {
        title: t("stock.stockLeverManage"),
        img: require("@/assets/images/stock-lever.png"),
        show: true,
        children: [
          {
            name: t("lever.contractposition"),
            path: "/person/stockContract?active=1",
            show: true,
          },
          {
            name: t("global.currentCommission"),
            path: "/person/stockContract?active=2",
            show: true,
          },
          {
            name: t("lever.transRecord"),
            path: "/person/stockContract?active=3",
            show: true,
          },
        ],
      },
      {
        title: t("user.note"),
        img: require("@/assets/images/msg.png"),
        show:
          platform_content_management.value[0] ||
          platform_content_management.value[1] ||
          platform_content_management.value[2],
        children: [
          {
            name: t("user.announce"),
            path: "/announce",
            show: platform_content_management.value[1],
          },
          {
            name: t("global.helpCenter"),
            path: "/helpcenter",
            show: platform_content_management.value[0],
          },
          {
            name: t("global.newsList"),
            path: "/news",
            show: platform_content_management.value[2],
          },
        ],
      },
    ]);
    watch(
      () => virtual_wallet.value,
      (val) => {
        slibar.value = [
          {
            title: t("user.person"),
            img: require("@/assets/images/person.png"),
            show: true,
            children: [
              {
                name: t("user.accountSettings"),
                path: "/person/accountSet",
                show: true,
              },
              { name: t("user.invite"), path: "/person/myinvita", show: true },
            ],
          },
          {
            title: t("NFT.manage"),
            img: require("@/assets/images/NFT.png"),
            show: !val && NFTStatus.value,
            children: [
              {
                name: t("NFT.MyCollection"),
                path: "/person/NFTManage?active=1",
                show: true,
              },
              {
                name: t("NFT.transRecord"),
                path: "/person/NFTManage?active=2",
                show: true,
              },
              {
                name: t("NFT.TransferRecord"),
                path: "/person/NFTManage?active=3",
                show: true,
              },
            ],
          },
          {
            title: t("user.assets"),
            img: require("@/assets/images/asset.png"),
            show: true,
            children: [
              {
                name: t("assets.myAssets"),
                path: "/person/myassets",
                show: true,
              },
              {
                name: t("assets.recharge", { space: "　　" }),
                path: "/person/recharge",
                show: !val,
              },
              {
                name: t("assets.withdrawMoney", { space: "　　" }),
                path: "/person/cash",
                show: !val,
              },
              {
                name: t("assets.assetRecord"),
                path: "/person/AssetsRecord",
                show: !val,
              },
            ],
          },
          {
            title: t("global.entrustedManagement"),
            img: require("@/assets/images/commit.png"),
            show: true,
            children: [
              {
                name: t("global.currentCommission"),
                path: "/person/ComminManage?active=1",
                show: true,
              },
              {
                name: t("global.historyCommission"),
                path: "/person/ComminManage?active=2",
                show: true,
              },
            ],
          },
          {
            title: t("global.contractManagement"),
            img: require("@/assets/images/contract.png"),
            show: true,
            children: [
              {
                name: t("global.currentPosition"),
                path: "/person/contract?active=1",
                show: true,
              },
              {
                name: t("global.currentCommission"),
                path: "/person/contract?active=2",
                show: true,
              },
              {
                name: t("global.historyRecord"),
                path: "/person/contract?active=3",
                show: true,
              },
            ],
          },
          {
            title: t("stock.stockInvestment"),
            img: require("@/assets/images/stock.png"),
            show: true,
            children: [
              {
                name: t("global.currentPosition"),
                path: "/person/stockTrad?active=1",
                show: true,
              },
              {
                name: t("global.currentCommission"),
                path: "/person/stockTrad?active=2",
                show: true,
              },
              {
                name: t("global.historyRecord"),
                path: "/person/stockTrad?active=3",
                show: true,
              },
            ],
          },
          {
            title: t("stock.stockLever"),
            img: require("@/assets/images/stock-lever.png"),
            show: true,
            children: [
              {
                name: t("lever.contractposition"),
                path: "/person/stockContract?active=1",
                show: true,
              },
              {
                name: t("global.currentCommission"),
                path: "/person/stockContract?active=2",
                show: true,
              },
              {
                name: t("lever.transRecord"),
                path: "/person/stockContract?active=3",
                show: true,
              },
            ],
          },
          {
            title: t("user.note"),
            img: require("@/assets/images/msg.png"),
            show: true,
            children: [
              { name: t("user.announce"), path: "/announce", show: true },
              { name: t("global.helpCenter"), path: "/helpcenter", show: true },
              { name: t("global.newsList"), path: "/news", show: true },
            ],
          },
        ];
        if (val) {
          let path = $route.fullPath;
          if (
            path === "/person/recharge" ||
            path === "/person/cash" ||
            path === "/person/AssetsRecord" ||
            path.indexOf("NFTManage") > 0
          ) {
            $router.replace("/person/accountSet");
          }
        }
      }
    );
    onMounted(() => {
      nextTick(() => {
        slibar.value = [
          {
            title: t("user.person"),
            img: require("@/assets/images/person.png"),
            show: true,
            children: [
              {
                name: t("user.accountSettings"),
                path: "/person/accountSet",
                show: true,
              },
              { name: t("user.invite"), path: "/person/myinvita", show: true },
            ],
          },
          {
            title: t("NFT.manage"),
            img: require("@/assets/images/NFT.png"),
            show: !virtual_wallet.value && NFTStatus.value,
            children: [
              {
                name: t("NFT.MyCollection"),
                path: "/person/NFTManage?active=1",
                show: true,
              },
              {
                name: t("NFT.transRecord"),
                path: "/person/NFTManage?active=2",
                show: true,
              },
              {
                name: t("NFT.TransferRecord"),
                path: "/person/NFTManage?active=3",
                show: true,
              },
            ],
          },
          {
            title: t("user.assets"),
            img: require("@/assets/images/asset.png"),
            show: true,
            children: [
              {
                name: t("assets.myAssets"),
                path: "/person/myassets",
                show: true,
              },
              {
                name: t("assets.recharge", { space: "　　" }),
                path: "/person/recharge",
                show: !virtual_wallet.value,
              },
              {
                name: t("assets.withdrawMoney", { space: "　　" }),
                path: "/person/cash",
                show: !virtual_wallet.value,
              },
              {
                name: t("assets.assetRecord"),
                path: "/person/AssetsRecord",
                show: !virtual_wallet.value,
              },
            ],
          },
          {
            title: t("global.entrustedManagement"),
            img: require("@/assets/images/commit.png"),
            show: true,
            children: [
              {
                name: t("global.currentCommission"),
                path: "/person/ComminManage?active=1",
                show: true,
              },
              {
                name: t("global.historyCommission"),
                path: "/person/ComminManage?active=2",
                show: true,
              },
            ],
          },
          {
            title: t("global.contractManagement"),
            img: require("@/assets/images/contract.png"),
            show: true,
            children: [
              {
                name: t("global.currentPosition"),
                path: "/person/contract?active=1",
                show: true,
              },
              {
                name: t("global.currentCommission"),
                path: "/person/contract?active=2",
                show: true,
              },
              {
                name: t("global.historyRecord"),
                path: "/person/contract?active=3",
                show: true,
              },
            ],
          },
          {
            title: t("stock.stockInvestment"),
            img: require("@/assets/images/stock.png"),
            show: true,
            children: [
              {
                name: t("global.currentPosition"),
                path: "/person/stockTrad?active=1",
                show: true,
              },
              {
                name: t("global.currentCommission"),
                path: "/person/stockTrad?active=2",
                show: true,
              },
              {
                name: t("global.historyRecord"),
                path: "/person/stockTrad?active=3",
                show: true,
              },
            ],
          },
          {
            title: t("stock.stockLever"),
            img: require("@/assets/images/stock-lever.png"),
            show: true,
            children: [
              {
                name: t("lever.contractposition"),
                path: "/person/stockContract?active=1",
                show: true,
              },
              {
                name: t("global.currentCommission"),
                path: "/person/stockContract?active=2",
                show: true,
              },
              {
                name: t("lever.transRecord"),
                path: "/person/stockContract?active=3",
                show: true,
              },
            ],
          },
          {
            title: t("user.note"),
            img: require("@/assets/images/msg.png"),
            show: true,
            children: [
              { name: t("user.announce"), path: "/announce", show: true },
              { name: t("global.helpCenter"), path: "/helpcenter", show: true },
              { name: t("global.newsList"), path: "/news", show: true },
            ],
          },
        ];
      });
      slibar.value.forEach((ele) => {
        if (ele.title === t("global.entrustedManagement")) {
          ele.show = bibiStatus.value;
        }
        if (ele.title === t("global.contractManagement")) {
          ele.show = leverStatus.value;
        }
        if (ele.title === t("NFT.manage")) {
          ele.show = !virtual_wallet.value && NFTStatus.value;
        }
      });
    });
    /**
     * methods
     */

    // 判断标题是否选中
    const titleActive = (item) => {
      let isclude = false;
      item.children.forEach((item1) => {
        if (item1.path.indexOf($route.path) >= 0) {
          isclude = true;
        }
      });
      return isclude;
    };
    const securityInfo = computed(() => {
      return $store.state.user.securityInfo;
    });
    const bibiStatus = computed(() => {
      let list = $store.state.user.systemInfo;
      let flag = false;
      list.forEach((ele) => {
        if (ele.key === "currency_transaction_status") {
          flag = ele.value;
        }
      });
      return flag;
    });
    const NFTStatus = computed(() => {
      let list = $store.state.user.systemInfo;
      let flag = false;
      list.forEach((ele) => {
        if (ele.key === "nft_entrance") {
          flag = ele.value;
        }
      });
      return flag;
    });
    const leverStatus = computed(() => {
      let list = $store.state.user.systemInfo;
      let flag = false;
      list.forEach((ele) => {
        if (ele.key === "lever_status") {
          flag = ele.value;
        }
      });
      return flag;
    });
    const confirm = () => {
      state.dialogSafetyTip = false;
      $router.push("/person/Advanced");
    };
    const viewSafeStatus = () => {
      state.dialogSafetyTip = false;
      $router.push("/person/Advanced");
    };
    const onCancel = () => {
      state.dialogSafetyTip = false;
    };
    // 判断名称是否选中
    const nameActive = (item) => {
      let isactive = false;
      let path = $route.fullPath.slice(3);
      if (
        item === path ||
        (item == "/person/cash" && path.includes("/person/cashPage"))
      ) {
        isactive = true;
      }
      return isactive;
    };
    const onVerfiedConfirm = () => {
      state.dialogVerfiedTip = false;
      $router.push("/person/Authentication");
    };
    const onVerfiedCancel = () => {
      state.dialogVerfiedTip = false;
    };
    //是否需要实名
    const isVerified = computed(() => {
      let list = $store.state.user.systemInfo;
      let flag = false;
      list.forEach((ele) => {
        if (ele.key === "verify_switch") {
          if (
            ele.value[5] &&
            $store.state.user.securityInfo.user_auth_level !== 2
          ) {
            if ($store.state.user.securityInfo.user_auth_level !== 4) {
              state.type = 1;
            }
            flag = true;
          }
        }
      });
      return flag;
    });
    const goUrl = (url) => {
      console.log("router", url);
      if (url === "/person/cash") {
        if (isVerified.value) {
          state.dialogVerfiedTip = true;
          return false;
        }
        if (
          securityInfo.value.user_safety_certificate_status &&
          securityInfo.value.user_safety_certificate_type !== 2
        ) {
          if (securityInfo.value.user_safety_certificate_type !== 4) {
            state.safetyType = 1;
          }
          state.dialogSafetyTip = true;
          return;
        }
        if (!c2c_withdraw.value && !bank_withdraw.value) {
          url = "/person/cashPage?type=1";
        }
      }
      $router.push(url);
    };
    return {
      state,
      titleActive,
      nameActive,
      goUrl,
      securityInfo,
      confirm,
      onCancel,
      onVerfiedConfirm,
      onVerfiedCancel,
      isVerified,
      viewSafeStatus,
      bibiStatus,
      leverStatus,
      NFTStatus,
      platform_content_management,
      virtual_wallet,
      slibar,
    };
  },
};
</script>

<style scoped lang="less">
.slidebar {
  width: 100%;
  padding: 30px 15px;
  box-sizing: border-box;
}
.round {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.title {
  margin-bottom: 7px;
}
.namebox {
  margin-bottom: 30px;
  padding-inline-start: 25px;
  padding-top: 16px;
  li {
    width: 100%;
    height: 32px;
    line-height: 32px;
  }
}
.router-link {
  width: 100%;
  word-break: break-word;
  // height: 32px;
  display: block;
  line-height: 1.2;
  padding: 7px 0;
}
.mr-5 {
  margin-inline-end: 5px;
}
</style>
