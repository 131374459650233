<template>
  <div>
    <Header
      :language="state.language"
      v-if="state.headerShow"
      :isLoginReg="state.isLoginReg"
    ></Header>
    <div style="height:60px" v-if="state.headerShow"></div>
    <router-view class="over-bg" />
    <Footer
      :language="state.language"
      v-if="state.isShow"
      :isLoginReg="state.isLoginReg"
    ></Footer>
  </div>
</template>
<script>
import {
  onMounted,
  ref,
  watch,
  reactive,
  computed,
  nextTick,
  getCurrentInstance,
  onBeforeUnmount,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Footer from "@/components/pcfooter";
import Header from "@/components/pcheader";
// import langList from "@/language/langList";
import { getLanguageList } from "@/api/system";
import { getTokenTime, removeToken, removeTokenTime } from "@/utils/auth";
import { getConfig, checkToken } from "@/api/user";
import UseSystemSetting from "@/commonapi/useSystemSetting";
export default {
  components: {
    Footer,
    Header,
  },
  setup() {
    const $store = useStore();
    const state = reactive({
      timer: null,
      timer1: null,
      isShow: true,
      tokenStatus: false,
      isLoginReg: false, //是否是登录页或者注册页或重置密码页
      headerShow: true,
      language: [],
      nowChangeLang: $store.state.user?.local,
    });
    const { ctx } = getCurrentInstance();
    const {
      appContext: {
        app: {
          config: { globalProperties },
        },
      },
    } = getCurrentInstance();
    const { $websocketTool } = globalProperties;
    // const language = reactive([]);
    const $route = useRoute();
    const $router = useRouter();
    // 获取开关状态
    const { default_language_tag } = UseSystemSetting();
    const themeStyle = process.env.VUE_APP_COLOR; // dark初始色调
    const mainPage = ref(false);
    const currentPage = ref("");
    const testToken = () => {
      if ($store.getters.token) {
        checkToken($store.getters.token).then((res) => {
          state.tokenStatus = res.data.value;
          if (state.tokenStatus) {
            sendOnlineMessage();
            $store.dispatch("user/changeSecurity");
          } else {
            $store.commit("user/SET_TOKEN", "");
            $store.commit("user/SET_TOKEN_TIME", "");
            removeToken();
            removeTokenTime();
          }
        });
      }
    };
    //用户信息
    const getUser = computed(() => {
      return $store.state.user.userInfo;
    });
    // 语言
    const local = computed(() => {
      return $store.state.user.local;
    });
    // 模式（黑夜或白天）
    const theme = computed(() => {
      return $store.state.exchange.theme;
    });
    // 合约交易或者秒合约交易路径
    const path = computed(() => {
      return $store.state.exchange.path;
    });
    const nowPathLang = computed(() => {
      let nowlang = "";
      let testLang = "";
      if (
        local.value === "en" &&
        (!localStorage.getItem("isFirset") ||
          localStorage.getItem("isFirset") === 1)
      ) {
        testLang = default_language_tag.value;
      } else {
        testLang = state.nowChangeLang;
      }

      if (testLang == "zh") {
        nowlang = "cn";
      } else if (testLang == "tc") {
        nowlang = "hk";
      } else if (testLang == "vn") {
        nowlang = "vi";
      } else {
        nowlang = testLang;
      }
      return nowlang;
    });
    watch(
      () => [$route.path, $store.state.user?.local],
      ([path, val]) => {
        if (
          path.includes(`/download`) ||
          path.includes(`/login`) ||
          path.includes(`/register`)
        ) {
          state.isLoginReg = true;
        } else {
          state.isLoginReg = false;
        }
        if (val == "zh") {
          state.nowChangeLang = "cn";
        } else if (val == "tc") {
          state.nowChangeLang = "hk";
        } else if (val == "vn") {
          state.nowChangeLang = "vi";
        } else {
          state.nowChangeLang = val;
        }
        // console.log("nowPathLang", nowPathLang.value, path);

        $store.commit("exchange/SET_PATH", path);
        // liveChat插件开始************************
        // if (
        //   path !== `/${state.nowChangeLang}/` &&
        //   path !== `/${state.nowChangeLang}/person/cash`
        // ) {
        //   console.log("隐藏");
        //   window.LiveChatWidget.call("hide");
        // }
        // liveChat插件结束************************
        if (path === "/download") {
          state.isShow = false;
        } else {
          state.isShow = true;
        }
        if (path.includes(`/maintenance`)) {
          state.isShow = false;
          state.headerShow = false;
        } else {
          state.isShow = true;
          state.headerShow = true;
        }
        if (
          path.includes(`/download`) ||
          path.includes(`/login`) ||
          path.includes(`/register`) ||
          path.includes(`/forgotPassword`)
        ) {
          state.isShow = false;
        } else {
          state.isShow = true;
        }

        if (
          (path.includes(`/deal`) ||
            path.includes(`/lever`) ||
            path.includes(`/secondlever`) ||
            path.includes(`/Stock`) ||
            path.includes(`/stockLever`) ||
            path.includes(`/stockSecond`) ||
            path.includes(`/foreignExchange`)) &&
          theme.value === "night"
        ) {
          document.getElementsByTagName("body")[0].className =
            "theme-style-dark mode-style-" + themeStyle;
        } else {
          document.getElementsByTagName("body")[0].className =
            "mode-style-" + themeStyle;
        }
        if (
          path === `/${state.nowChangeLang}/` ||
          path === `/${state.nowChangeLang}/quoetes` ||
          path === `/${state.nowChangeLang}/coinExchange` ||
          path === `/${state.nowChangeLang}/levelExchange` ||
          path === `/${state.nowChangeLang}/assets`
        ) {
          currentPage.value = path;
          mainPage.value = true;
        } else {
          if (path.includes(`/login`) || path.includes(`/register`)) {
            clearInterval(state.timer);
            state.timer = null;
            clearInterval(state.timer1);
            state.timer1 = null;
            if (path.includes(`/register`)) {
              if ($route.query.local) {
                $store
                  .dispatch("user/changeLocal", $route.query.local)
                  .then(() => {
                    ctx.$i18n.locale = $route.query.local;
                  });
              }
            }
          }
          // if (path.includes(`/forgotPassword`)) {
          //   state.isLoginReg = true;
          // }
          currentPage.value = path;
          mainPage.value = false;
        }
        // console.log("path", path, state.isLoginReg);
      }
    );
    watch(
      () => getUser.value?.id,
      (newValue) => {
        if (newValue && $websocketTool?.ws?.readyState === 1) {
          $websocketTool?.ws.send(
            JSON.stringify({
              socket_type: `ONLINE_${newValue}`,
              value: "PC",
              subscribed: 1,
            })
          );
        }
      }
    );
    watch(
      () => local.value,
      (val) => {
        // 在邀请链接进来的注册页调整语言
        if (val !== $route.query.local && $route.query.code) {
          let newQuery = { code: $route.query.code, local: val };
          $store.dispatch("user/changeLocal", val).then(() => {
            ctx.$i18n.locale = val;
          });
          $router.push({
            query: newQuery,
          });
        }
        //如果切换成阿拉伯语
        if (val === "ar") {
          document.querySelector("html").classList.add("is-rtl");
        } else {
          document.querySelector("html").classList.remove("is-rtl");
        }
      }
    );
    const sendOnlineMessage = () => {
      state.timer1 = setInterval(() => {
        if ($websocketTool?.ws && $websocketTool?.ws?.readyState === 1) {
          clearInterval(state.timer1);
          state.timer1 = null;
          if (getUser.value?.id) {
            $websocketTool?.ws.send(
              JSON.stringify({
                socket_type: `ONLINE_${getUser.value?.id}`,
                value: "PC",
                subscribed: 1,
              })
            );
          }
        }
      }, 3000);
    };
    //语言列表
    const getAllLanguageList = () => {
      getLanguageList().then((res) => {
        let ary = [];
        res.data.forEach((ele, index) => {
          if (ele.language_tag == "zh") {
            ele.label = "中文简体";
            ary.push(ele);
          } else if (ele.language_tag == "tc") {
            ele.label = "中文繁體";
            ary.push(ele);
          } else if (ele.language_tag == "en") {
            ele.label = "English";
            ary.push(ele);
          } else if (ele.language_tag == "ja") {
            ele.label = "日本語";
            ary.push(ele);
          } else if (ele.language_tag == "in") {
            ele.label = "हिन्दी";
            ary.push(ele);
          } else if (ele.language_tag == "vn") {
            ele.label = "Tiếng Việt";
            ary.push(ele);
          } else if (ele.language_tag == "ko") {
            ele.label = "한국인";
            ary.push(ele);
          } else if (ele.language_tag == "th") {
            ele.label = "ไทย";
            ary.push(ele);
          } else if (ele.language_tag === "id") {
            ele.label = "Indonesia";
            ary.push(ele);
          } else if (ele.language_tag === "es") {
            ele.label = "Español";
            ary.push(ele);
          } else if (ele.language_tag === "pt") {
            ele.label = "Português";
            ary.push(ele);
          } else if (ele.language_tag === "it") {
            ele.label = "Italiano";
            ary.push(ele);
          } else if (ele.language_tag === "ru") {
            ele.label = "Русский язык";
            ary.push(ele);
          } else if (ele.language_tag === "de") {
            ele.label = "Deutsch";
            ary.push(ele);
          } else if (ele.language_tag === "fr") {
            ele.label = "Français";
            ary.push(ele);
          } else if (ele.language_tag === "ar") {
            ele.label = "عربي";
            ary.push(ele);
          } else if (ele.language_tag === "no") {
            ele.label = "Norsk";
            ary.push(ele);
          } else if (ele.language_tag === "fi") {
            ele.label = "Suomi";
            ary.push(ele);
          } else if (ele.language_tag === "tr") {
            ele.label = "Türkçe";
            ary.push(ele);
          } else {
            res.data.splice(index, 1);
          }
        });
        state.language = ary;
        $store.commit("user/SET_LOCALARY", ary);
        getSysConfig();
      });
    };
    const getSysConfig = () => {
      getConfig().then((res) => {
        if (res.code === 200) {
          $store.commit("user/SET_SYSTEMINFO", null);
          res.data.forEach((data) => {
            if (data.key === "default_language_tag") {
              if (local.value === "en" && !localStorage.getItem("isFirset")) {
                state.nowChangeLang = data.value;
                $store.dispatch("user/changeLocal", data.value);
                localStorage.setItem("isFirset", 1);
                ctx.$i18n.locale = data.value;
                // $router.go(0);
              } else {
                localStorage.setItem("isFirset", 2);
              }
            }
            // 米多客开始************************
            // if (data.key === "service_url") {
            //   setTimeout(() => {
            //     let ele = document.getElementById("MICHAT-FLOAT-WRAP");
            //     if (ele) {
            //       if (data.value == "contact") {
            //         ele.style.display = "none";
            //       } else {
            //         ele.style.display = "block";
            //       }
            //     }
            //   }, 500);
            // }
            // 米多客结束************************
          });
          $store.commit("user/SET_SYSTEMINFO", res.data);
        }
      });
    };
    onMounted(() => {
      let path = location.pathname;
      // console.log(path);
      if (
        path.includes(`/download`) ||
        path.includes(`/login`) ||
        path.includes(`/register`)
      ) {
        state.isLoginReg = true;
      } else {
        state.isLoginReg = false;
      }
      nextTick(() => {
        getSysConfig();
        $store.dispatch("user/getProtocolConfig");
      });
      getAllLanguageList();
      $store.dispatch("user/changeTheme", themeStyle).then(() => {
        document.getElementsByTagName("body")[0].className =
          "mode-style-" + themeStyle;
      });
      //获取安全认证信息
      testToken();

      let tokenTime = getTokenTime();
      // let nowTime = Date.parse(new Date()) / 1000;
      if ($store.getters.token && tokenTime) {
        let time = tokenTime * 1000;
        state.timer = setInterval(function() {
          $store.dispatch("user/resetToken");
        }, time);
      }
      ctx.$i18n.locale = local.value;
      if (local.value === "ar") {
        document.querySelector("html").classList.add("is-rtl");
      } else {
        document.querySelector("html").classList.remove("is-rtl");
      }
    });
    onBeforeUnmount(() => {
      clearInterval(state.timer);
      state.timer = null;
      clearInterval(state.timer1);
      state.timer1 = null;
    });
    return {
      themeStyle,
      mainPage,
      currentPage,
      getUser,
      state,
      sendOnlineMessage,
      theme,
      path,
      testToken,
      getAllLanguageList,
      nowPathLang,
      default_language_tag,
    };
  },
  methods: {
    localSocket() {
      let that = this;
      if ("WebSocket" in window && window.VUE_APP_WEBSOCK) {
        // location.host
        that.ws = new WebSocket(window.VUE_APP_WEBSOCK);
        that.$websocketTool.setWs(that.ws);
        that.ws.onopen = function() {};
        that.ws.onerror = () => {};

        that.ws.onclose = () => {
          setTimeout(() => {
            that.localSocket();
          }, 5000);
        };
      }
    },
  },
  created() {
    this.$nextTick(() => {
      this.$store.dispatch("user/changeSystemConfig");
    });
    console.log("first", this.default_language_tag);
    document.title = window.VUE_APP_NAME;
    // let nowlocal = "";
    // if (this.$store.state.user?.local == "zh") {
    //   nowlocal = "zh-cn";
    // } else if (this.$store.state.user?.local == "tc") {
    //   nowlocal = "zh-hk";
    // } else {
    //   nowlocal = this.$store.state.user?.local;
    // }
    // window.location.href = window.location.pathname + "#" + nowlocal;
    this.localSocket();
  },
  beforeUnmount() {
    if (this.ws) {
      this.ws.close();
    }
  },
};
</script>
<style lang="less">
#app {
  font-family: "Dinpro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  text-align: center;
  color: #2c3e50;
}
.pd-bt-80 {
  padding-bottom: 80px;
}
.is-rtl {
  .msg-box {
    padding: 0px 15px;
  }
}
</style>
