<template>
  <div class="welcome" :class="diyClass">
    <div class="share-color title" v-if="bannerLength == 0">
      {{ state.appName }}{{ $t("foreign.forexHeadTip") }}
    </div>
    <div class="share-color title mg-bt-xl" v-if="bannerLength == 0">
      {{ $t("foreign.forexBetterTip") }}
    </div>
    <!-- <div class="info">{{ $t("tips.welcomeInfo") }}</div> -->
    <div class="flex js-st" v-if="!state.islogin" @click="stop">
      <el-input
        style="width:245px"
        v-model="state.content"
        :placeholder="$t('register.registerTip')"
      ></el-input>
      <div class="btn reg-btn point" @click.stop="toReg">
        {{ $t("register.signUpNow") }}
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { emailRuleHeader, phoneRuleHeader } from "@/utils/headerForm";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
export default {
  name: "welcome",
  props: {
    diyClass: String,
    bannerLength: Number,
  },
  setup() {
    const $store = useStore();
    const state = reactive({
      content: "",
      appName: window.VUE_APP_NAME,
      islogin: $store.state.user.token ? true : false,
    });
    const { t } = useI18n();
    const $router = useRouter();
    watch(
      () => $store.state.user.token,
      (val) => {
        if (val) {
          state.islogin = true;
        } else {
          state.islogin = false;
        }
      }
    );
    const toReg = () => {
      let tab = 0;
      $store.commit("user/SET_ACCOUNTREG_TAB", 2);
      if (state.content) {
        if (
          phoneRuleHeader.reg.test(state.content) ||
          emailRuleHeader.reg.test(state.content)
        ) {
          if (phoneRuleHeader.reg.test(state.content)) {
            tab = 1;
            $store.commit("user/SET_TAB", tab);
            $store.commit("user/SET_ACCOUNTREG", state.content);
          } else if (emailRuleHeader.reg.test(state.content)) {
            tab = 2;
            $store.commit("user/SET_TAB", tab);
            $store.commit("user/SET_ACCOUNTREG", state.content);
          }
        } else {
          ElMessage.error(t("register.registerTip"));
          return false;
        }
      }
      state.content = "";

      $router.push("/register");
    };
    const stop = (event) => {
      event.stopPropagation();
    };
    return {
      toReg,
      state,
      stop,
    };
  },
};
</script>

<style lang="less" scoped>
.welcome {
  width: 700px;
  padding: 0 40px;
}
.title {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  width: 100%;
  text-align: start;
}
.name {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 8px;
  text-align: start;
}
.info {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #7a7a7a;
  margin-bottom: 24px;
  text-align: start;
}
.btn {
  width: 96px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  color: white;
  margin-inline-start: 20px;
}
</style>
